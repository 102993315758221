import {
    Collapse,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableRow
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";
import { Root, classes } from "../../assets/styles/components/SectionRow";
import NoResults from "../../components/NoResults";
import CustomTooltip from "../../components/UI/CustomTooltip";
import Icon from "../../components/UI/Icon";
// import { findItemsById } from "../../utils/Items/item-helper";
import AuthGlobal from "../../context/store/global";
import { useAlert } from "../../hooks/useAlert";
import { arrayMoveImmutable } from "array-move";
import Alert from "../../components/UI/Alert";
import { formatItemPrice } from "../../utils/Items/pricing";
import ConfirmAction from "../../components/modals/ConfirmAction";

const DragHandle = SortableHandle(() => {
    return (
        <CustomTooltip title="Drag to Order">
            <span>
                <Icon name={"drag"} style={{ margin: 16 }} />
            </span>
        </CustomTooltip>
    );
});

const DragItemHandler = SortableHandle(() => {
    return (
        <CustomTooltip title="Drag to Order">
            <span>
                <Icon name="drag" style={{ margin: 16 }} />
            </span>
        </CustomTooltip>
    );
});

const TableBodySortable = SortableContainer(({ children }) => {
    return <TableBody>{children}</TableBody>;
});

const ItemRow = SortableElement(
    ({
        data: { index, item, toggleItemStatus, handleOpenEditItem, removeItem }
    }) => {
        // const classes = useStyles();
        const {
            globalState: { current_restaurant }
        } = useContext(AuthGlobal);
        return (
            <TableRow
                key={index}
                className={`${classes.itemRow} ${
                    item.item.status === "active" ? "" : classes.itemInactive
                }`}
            >
                <td className={classes.itemDrag}>
                    <DragItemHandler />
                </td>
                <td
                    style={{
                        padding: "0px 0px 0px 0px",
                        flexGrow: 1,
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    {item.item.type === "title" && (
                        <>
                            <span
                                style={{
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    lineHeight: "19px",
                                    width: "100%",
                                    paddingBottom: 6,
                                    borderBottom: "2px solid rgba(0, 0, 0, 0.5)"
                                }}
                            >
                                {item.item.internal_name}
                            </span>
                        </>
                    )}
                    {item.item.type === "item" && (
                        <>
                            <span
                                style={{
                                    marginRight: 16,
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "19px"
                                }}
                            >
                                {item.item.internal_name}
                            </span>
                            <span
                                style={{
                                    fontWeight: 300,
                                    fontSize: "14px"
                                }}
                            >
                                {item.item.price_variations
                                    .map((x) => {
                                        const currencySymbol =
                                            current_restaurant.currency_symbol;
                                        const currencyPosition =
                                            current_restaurant.currency_placement;

                                        return formatItemPrice(
                                            {
                                                price_value: x.price_value,
                                                leading_text: x.leading_text,
                                                trailing_text: x.trailing_text
                                            },
                                            currencyPosition,
                                            currencySymbol
                                        );
                                    })
                                    .join(", ")}
                            </span>
                        </>
                    )}
                </td>
                <td style={{ padding: 0 }}>
                    <div className={classes.actions} style={{ height: 53 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={item.item.status === "active"}
                                    classes={{
                                        switchBase: classes.switchDisabled
                                    }}
                                    className={
                                        item.item.status === "active"
                                            ? classes.switch
                                            : ""
                                    }
                                />
                            }
                            label={
                                item.item.status === "active"
                                    ? "Active"
                                    : "Inactive"
                            }
                            onChange={() => toggleItemStatus(index)}
                            className={classes.switchLabel}
                        />
                        <CustomTooltip title="Edit item">
                            <span onClick={() => handleOpenEditItem(index)}>
                                <Icon name="edit" />
                            </span>
                        </CustomTooltip>
                        <CustomTooltip title="Remove item">
                            <span onClick={() => removeItem(index)}>
                                <Icon name="remove" />
                            </span>
                        </CustomTooltip>
                    </div>
                </td>
            </TableRow>
        );
    }
);

const uniqueIdCounter = (() => {
    let count = 0;
    return () => ++count;
})();

const SectionRow = ({
    section,
    expanded,
    setExpanded,
    index,
    handleAddItemsForm,
    handleOpenEdit,
    handleOpenDelete,
    handleOpenTitleForm,
    refreshList,
    setSelectedItem,
    showEditItemForm,
    setShowEditItemForm,
    loading,
    onUpdatedSection,
    handleFindItemsById,
    setHandleFindItemsById,
    setPreveItemRefresh,
    preveItemRefresh,
    onSortSection,
    onRemoveItem,
    onToggleItem,
    setNewItemsList,
    newItemsList
}) => {
    const {
        globalState: {
            current_restaurant: { identifier }
        }
    } = useContext(AuthGlobal);

    const [active, setActive] = useState(section.status === "active");
    const [alert] = useAlert();
    const [items, setItems] = useState(section.item_links);
    const [firstLoad, setFirstLoad] = useState(true);
    const [editedIndex, setEditedIndex] = useState(-1);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);

    const sortableClassName = `sortable-tbody-row-${Date.now()}-${uniqueIdCounter()}`;

    // Use useEffect to update items when section prop changes
    useEffect(() => {
        setItems(section.item_links);
    }, [section.item_links]);

    useEffect(() => {
        if (!firstLoad) {
            onUpdatedSection(
                { status: active ? "active" : "inactive" },
                section._id
            );
            refreshList(
                { ...section, status: active ? "active" : "inactive" },
                index
            );
        }
        setFirstLoad(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, identifier]);

    const onSortEnd = (arr) => {
        let item_links = [...section.item_links];
        item_links = arrayMoveImmutable(
            item_links,
            arr.oldIndex,
            arr.newIndex
        ).map(({ item }, index) => ({ item, sort_order: index + 1 }));

        setItems(item_links);

        const updatedItemLinks = item_links.map((item, index) => ({
            item_id: item.item._id,
            sort_order: index + 1
        }));

        const sortItemsData = {
            item_links: updatedItemLinks,
            sectionId: section._id
        };

        setNewItemsList((prev) => {
            const updatedList = [...prev]; // Create a copy of the previous array
            const sectionIndex = updatedList.findIndex(
                (item) => item.sectionId === sortItemsData.sectionId
            ); // Find the index of the object with the same sectionId

            if (sectionIndex !== -1) {
                // If sectionId exists, update the item_links array
                updatedList[sectionIndex].item_links = updatedItemLinks;
            } else {
                // If sectionId is new, create a new object and add it to the array
                updatedList.push(sortItemsData);
            }
            return updatedList;
        });

        onSortSection(
            {
                item_links: item_links.map((item, index) => ({
                    item_id: item.item._id,
                    sort_order: index + 1
                }))
            },
            section._id
        );
        refreshList({ ...section, item_links }, index);
    };

    useEffect(() => {
        // const refreshItems = async () => {
        //     const { success, response } = await findItemsById(
        //         section.item_links.map((item) => item.item._id),
        //         identifier
        //     );

        //     if (success && response) {
        //         setItems(
        //             response.map((item, index) => ({
        //                 item,
        //                 sort_order: index + 1
        //             }))
        //         );
        //         setHandleFindItemsById(false);
        //     }
        // };
        if (expanded) {
            if (preveItemRefresh) {
                // refreshItems();
            }
            setPreveItemRefresh(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        handleFindItemsById,
        expanded,
        section,
        showEditItemForm,
        loading,
        identifier
    ]);

    const removeItem = (idx) => {
        setShowRemoveModal(true);
        setEditedIndex(idx);
    };

    const handleRemoveItem = (idx) => {
        setDisableBtn(true);
        const newItems = items.filter((x, i) => i !== idx);
        onRemoveItem(
            {
                item_links: newItems.map(
                    ({ item: { _id: item_id }, sort_order }) => ({
                        item_id,
                        sort_order
                    })
                )
            },
            section._id
        );
        refreshList(
            {
                ...section,
                item_links: newItems
            },
            index
        );
        setItems(newItems);
        setShowRemoveModal(false);
        setDisableBtn(false);
    };

    const handleOpenEditItem = (index) => {
        setSelectedItem(items[index]?.item || {});
        setShowEditItemForm(true);
    };

    const toggleItemStatus = async (index) => {
        const { _id: itemId, status } = items[index].item;
        const newItems = [...items];
        newItems[index].item.status =
            status === "active" ? "inactive" : "active";
        onToggleItem(itemId, status);
        // setItems(newItems);
    };

    return (
        <>
            <Alert {...alert} />
            <Root
                className={`${classes.container} ${
                    section.status === "inactive" && classes.transparent
                }`}
            >
                <td
                    style={{
                        height: 75,
                        display: "flex",
                        alignItems: "center",
                        width: "100%"
                    }}
                >
                    <span
                        onClick={() => setExpanded(expanded ? -1 : index)}
                        className="expand_section"
                        style={{
                            height: "50px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        {expanded ? (
                            <Icon
                                name={"arrowDown"}
                                className={classes.arrowDownButton}
                                // style={{ marginLeft: 31, marginRight: 29 }}
                            />
                        ) : (
                            <Icon
                                name={"arrowRight"}
                                className={classes.arrowRightButton}
                                // style={{ marginLeft: 31, marginRight: 29 }}
                            />
                        )}
                    </span>
                    <span
                        style={{
                            fontSize: "18px",
                            lineHeight: "21px",
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                            flexGrow: 1
                        }}
                    >
                        <span style={{ fontWeight: 500 }}>
                            {section.internal_name}
                        </span>
                        <span style={{ fontWeight: 300 }}>
                            ({section.item_links?.length || 0})
                        </span>
                    </span>
                    {!expanded && (
                        <div className={classes.actions}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={section.status === "active"}
                                        classes={{
                                            switchBase: classes.switchDisabled
                                        }}
                                        className={
                                            section.status === "active"
                                                ? classes.switch
                                                : ""
                                        }
                                    />
                                }
                                label={
                                    section.status === "active"
                                        ? "Active"
                                        : "Inactive"
                                }
                                onChange={() => setActive((val) => !val)}
                                className={classes.switchLabel}
                            />
                            <CustomTooltip title="Edit section">
                                <span
                                    onClick={() =>
                                        handleOpenEdit(section, index)
                                    }
                                >
                                    <Icon name="edit" />
                                </span>
                            </CustomTooltip>
                            <CustomTooltip title="Delete section">
                                <span
                                    onClick={() => handleOpenDelete(index)}
                                    style={{ marginRight: 14 }}
                                >
                                    <Icon name="trash" />
                                </span>
                            </CustomTooltip>

                            <DragHandle />
                        </div>
                    )}
                    {expanded && (
                        <div
                            className={classes.actions}
                            style={{ marginRight: 28 }}
                        >
                            <div
                                className={classes.addSectionButton}
                                style={{ marginRight: 16 }}
                                onClick={() => {
                                    handleOpenTitleForm(index);
                                }}
                            >
                                <Icon name="plus" />
                                Add Title
                            </div>
                            <div
                                className={
                                    "add_items_button " +
                                    classes.addSectionButton
                                }
                                onClick={() => {
                                    handleAddItemsForm(index);
                                }}
                            >
                                <Icon name="plus" />
                                Add Item(s)
                            </div>
                        </div>
                    )}
                </td>
                <Collapse in={expanded} component="td" style={{ padding: 0 }}>
                    <div
                        style={{
                            borderTop: "1px solid #D3D3D3",
                            margin: "0 30px",
                            width: "calc(100% - 60px)",
                            background: "white",
                            marginBottom: 20
                        }}
                    >
                        {section.item_links?.length === 0 && (
                            <NoResults text="No items found" />
                        )}
                        {section.item_links?.length > 0 && (
                            <Table className={sortableClassName}>
                                <TableBodySortable
                                    lockAxis="y"
                                    useDragHandle
                                    onSortEnd={onSortEnd}
                                    helperClass={classes.sorting}
                                    helperContainer={() =>
                                        document.querySelector(
                                            `.${sortableClassName}`
                                        )
                                    }
                                >
                                    {!loading &&
                                        items.map((item, index) => (
                                            <ItemRow
                                                index={index}
                                                key={index}
                                                data={{
                                                    index,
                                                    item,
                                                    toggleItemStatus,
                                                    handleOpenEditItem,
                                                    removeItem
                                                }}
                                            />
                                        ))}
                                </TableBodySortable>
                            </Table>
                        )}
                    </div>
                    <ConfirmAction
                        actionConfirm={() => handleRemoveItem(editedIndex)}
                        actionCancel={() => setShowRemoveModal(false)}
                        dialogText={`Are you sure you want to delete the ${
                            items?.filter((x, i) => i === editedIndex)[0]?.item
                                ?.display_name
                        } from the ${section?.display_name}?`}
                        icon="trash"
                        open={showRemoveModal}
                        disableBtn={disableBtn}
                    />
                </Collapse>
            </Root>
        </>
    );
};

export default SectionRow;
