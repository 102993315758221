import { useContext, useState } from "react";
import Icon from "../../components/UI/Icon";

import FormUpdateItem from "../../components/items/FormUpdateItem";
import { TableRow } from "@mui/material";
import NoResults from "../../components/NoResults";
import { Root, classes } from "../../assets/styles/pages/menus/tabs/Active";
import CustomTooltip from "../../components/UI/CustomTooltip";
import CustomPagination from "../../components/UI/CustomPagination";
import ConfirmAction from "../../components/modals/ConfirmAction";
import Alert from "../../components/UI/Alert";
import AuthGlobal from "../../context/store/global";
import { formatItemPrice } from "../../utils/Items/pricing";

const Actions = ({
    status,
    data: {
        showMarkAsActiveModal,
        showEditItemModal,
        showDuplicateModal,
        showArchiveModal,
        showDeleteModal,
        showRecoverModal,
        showPermanentDeleteModal,
        ...data
    }
}) => {
    return (
        <td className={classes.actions}>
            {status === "inactive" && (
                <CustomTooltip title="Move to Active">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="refresh"
                            onClick={() =>
                                showMarkAsActiveModal(
                                    data._id,
                                    data.internal_name
                                )
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {(status === "active" || status === "inactive") && (
                <CustomTooltip title="Edit item">
                    <span
                        className={"edit_item_button " + classes.iconContainer}
                    >
                        <Icon
                            name="edit"
                            onClick={() => showEditItemModal(data)}
                        />
                    </span>
                </CustomTooltip>
            )}
            {(status === "active" || status === "inactive") && (
                <CustomTooltip title="Duplicate item">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="duplicate"
                            onClick={() =>
                                showDuplicateModal(data._id, data.internal_name)
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {status === "active" && (
                <CustomTooltip title="Archive item">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="archive"
                            onClick={() =>
                                showArchiveModal(data._id, data.internal_name)
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {status === "trash" && (
                <CustomTooltip title="Recover item">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="restore"
                            onClick={() =>
                                showRecoverModal(data._id, data.internal_name)
                            }
                        />
                    </span>
                </CustomTooltip>
            )}
            {["active", "inactive", "trash"].includes(status) && (
                <CustomTooltip title="Delete item">
                    <span className={classes.iconContainer}>
                        <Icon
                            name="trash"
                            onClick={() => {
                                if (status !== "trash") {
                                    showDeleteModal(
                                        data._id,
                                        data.internal_name
                                    );
                                } else {
                                    showPermanentDeleteModal(
                                        data._id,
                                        data.internal_name
                                    );
                                }
                            }}
                        />
                    </span>
                </CustomTooltip>
            )}
        </td>
    );
};

const Row = ({ data: { status }, data }) => {
    const {
        globalState: { current_restaurant }
    } = useContext(AuthGlobal);
    return (
        <TableRow
            className={`${classes.menuListTableRow} ${
                data.index % 2 === 0 ? classes.color1 : classes.color2
            }`}
        >
            <td className={classes.name}>
                <span className={classes.itemName}>{data.internal_name}</span>
                <span className={classes.prices}>
                    {data.price_variations
                        .map((x) => {
                            const currencySymbol =
                                current_restaurant.currency_symbol;
                            const currencyPosition =
                                current_restaurant.currency_placement;

                            return formatItemPrice(
                                {
                                    price_value: x.price_value,
                                    leading_text: x.leading_text,
                                    trailing_text: x.trailing_text
                                },
                                currencyPosition,
                                currencySymbol
                            );
                        })
                        .join(", ")}
                </span>
            </td>
            <td>
                {data.image_url && (
                    /* eslint-disable-next-line jsx-a11y/img-redundant-alt */
                    <img
                        className={classes.itemImage}
                        src={data.image_url}
                        alt="Item image"
                    />
                )}
            </td>
            {!data.hideActions && <Actions status={status} data={data} />}
        </TableRow>
    );
};

const ItemsList = ({
    data,
    duplicateElementItem,
    setPagination,
    markAsTrash,
    markAsActive,
    markAsInactive,
    deleteElementItem,
    status = "active",
    hideActions = null,
    flagReload, //Flag to repeat the load of the items when closing the modals,
    handleAlert
}) => {
    const [modalVisible, setModalVisible] = useState("");
    const [itemName, setItemName] = useState("");
    const [selectedItem, setSelectedItem] = useState("");
    const [page, setPage] = useState(1);
    const rowsPerPage = setPagination ? 10 : data.length;

    const showDuplicateModal = (id, name) => {
        setItemName(name);
        setModalVisible("duplicate");
        setSelectedItem(id);
    };

    const showMarkAsActiveModal = (id, name) => {
        setItemName(name);
        setModalVisible("active");
        setSelectedItem(id);
    };

    const showArchiveModal = (id, name) => {
        setItemName(name);
        setModalVisible("archive");
        setSelectedItem(id);
    };

    const showDeleteModal = (id, name) => {
        setItemName(name);
        setModalVisible("trash");
        setSelectedItem(id);
    };

    const showRecoverModal = (id, name) => {
        setItemName(name);
        setModalVisible("recover");
        setSelectedItem(id);
    };

    const showPermanentDeleteModal = (id, name) => {
        setItemName(name);
        setModalVisible("delete");
        setSelectedItem(id);
    };

    const showEditItemModal = (record) => {
        setItemName(record.internal_name);
        setModalVisible("edit");
        setSelectedItem(record);
    };

    const okDeleteClick = () => {
        markAsTrash(selectedItem);
        closeAndReload();
    };

    const okArchiveClick = () => {
        markAsInactive(selectedItem);
        closeAndReload();
    };

    const okDuplicateClick = (newName) => {
        duplicateElementItem(selectedItem, newName);
        setItemName("");
        closeAndReload();
    };

    const okMarkAsActiveClick = () => {
        markAsActive(selectedItem);
        closeAndReload();
    };

    const okDeletePermanent = () => {
        deleteElementItem(selectedItem);
        closeAndReload();
    };

    const closeModal = () => {
        setItemName("");
        setModalVisible("");
    };

    const closeAndReload = (preventReload, alertData) => {
        if (!preventReload) {
            if (alertData) handleAlert(alertData);
            flagReload((x) => !x);
        }
        closeModal();
    };

    return (
        <>
            <Alert {...alert} />
            <div>
                {data.length > 0 ? (
                    <Root
                        style={{
                            marginTop: 15,
                            display: "block",
                            minHeight: "calc(100vh - 335px)",
                            overflowY: "auto"
                        }}
                    >
                        <tbody>
                            {data
                                .slice(
                                    (page - 1) * rowsPerPage,
                                    page * rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <Row
                                            index={index}
                                            key={index}
                                            data={{
                                                ...row,
                                                index,
                                                showMarkAsActiveModal,
                                                showEditItemModal,
                                                showDuplicateModal,
                                                showArchiveModal,
                                                showDeleteModal,
                                                showRecoverModal,
                                                showPermanentDeleteModal,
                                                status,
                                                hideActions
                                            }}
                                        ></Row>
                                    );
                                })}
                        </tbody>
                    </Root>
                ) : (
                    <NoResults text="No items found" />
                )}

                {setPagination && (
                    <CustomPagination
                        totalRows={data.length}
                        rowsPerPage={rowsPerPage}
                        handlePageChange={setPage}
                    />
                )}
            </div>

            <ConfirmAction
                open={modalVisible === "trash"}
                icon="trash"
                actionCancel={closeModal}
                actionConfirm={okDeleteClick}
                dialogText={`Are you sure want to delete the ${itemName} item?`}
            />

            <ConfirmAction
                open={modalVisible === "active"}
                icon="refresh"
                actionCancel={closeModal}
                actionConfirm={okMarkAsActiveClick}
                dialogText={`Are you sure want to move the ${itemName} item to active?`}
            />

            <ConfirmAction
                open={modalVisible === "recover"}
                icon="restore"
                actionCancel={closeModal}
                actionConfirm={okArchiveClick}
                dialogText={`Are you sure want to recover the ${itemName} item?`}
            />

            {itemName && (
                <ConfirmAction
                    open={modalVisible === "duplicate"}
                    icon="duplicate"
                    actionCancel={closeModal}
                    actionConfirm={okDuplicateClick}
                    dialogText={`Are you sure want to duplicate the ${itemName} item?`}
                    params={[
                        {
                            label: "New Item Name",
                            value: `Copy of ${itemName}`,
                            description: "Change the Item Name:"
                        }
                    ]}
                />
            )}

            <ConfirmAction
                open={modalVisible === "archive"}
                icon="archive"
                actionCancel={closeModal}
                actionConfirm={okArchiveClick}
                dialogText={`Are you sure want to archive the ${itemName} item?`}
            />

            <ConfirmAction
                open={modalVisible === "delete"}
                icon="trash"
                actionCancel={closeModal}
                actionConfirm={okDeletePermanent}
                dialogText={`Are you sure want to permanently delete the ${itemName} item? It will be gone for good.`}
            />

            <FormUpdateItem
                onClose={(preventReload, alertData, _newItem) => {
                    closeAndReload(preventReload, alertData);
                }}
                show={modalVisible === "edit"}
                item={modalVisible === "edit" ? selectedItem : {}}
            />
        </>
    );
};

export default ItemsList;
