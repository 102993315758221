import {
    SET_CURRENT_USER,
    SET_CURRENT_RESTAURANT,
    SET_ALERT,
    SET_LOCATION
} from "../actions";
import isEmpty from "../validations/isEmpty";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case SET_CURRENT_RESTAURANT:
            return {
                ...state,
                current_restaurant: action.payload
            };
        case SET_ALERT:
            return {
                ...state,
                alert: action.payload
            };
        case SET_LOCATION:
            return {
                ...state,
                location: action.payload
            }
        default:
            return state;
    }
}
