import { Root, classes } from "../assets/styles/components/RestaurantSelector";
import AuthGlobal from "../context/store/global";
import { useContext, useEffect, useState } from "react";
import Icon from "./UI/Icon";
import { Modal } from "@mui/material";
import { setCurrentRestaurant } from "../context/actions";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { client } from "../utils/apiClient";
import SearchBar from "./UI/SearchBar";
// import CustomSwitch from "./UI/CustomSwitch";
// import { toggleStatus } from "../utils/Restaurants/restaurant-helper";

const RestaurantSelector = () => {
    const {
        dispatch,
        globalState: {
            user,
            current_restaurant: { _id, display_name }
        }
    } = useContext(AuthGlobal);
    const { boot, shutdown } = useIntercom();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");

    const handleChangeRestaurant = async (target) => {
        navigate("/");
        dispatch(
            setCurrentRestaurant({
                ...target.identifier
            })
        );
        shutdown();
        await client(
            `user/${user._id}/switch-restaurant`,
            {
                identifier: target.identifier.identifier
            },
            "PUT"
        );
        boot({
            alignment: "right",
            email: user.email,
            customAttributes: {
                companyId: target.identifier.intercom_company_id,
                companyName: target.identifier.company_name
            },
            name: `${user.first_name}`,
            userId: user._id,
            company: {
                companyId: target.identifier.intercom_company_id,
                name: target.identifier.company_name
            }
        });
        setOpen(false);
    };

    useEffect(() => {
        let opt = user.user_permissions;
        if (user.role !== "super_admin") {
            opt = user.user_permissions.filter((x) => x.status === "active");
        }
        opt = opt.sort((x, y) =>
            x.identifier.display_name.localeCompare(
                y.identifier.display_name,
                undefined,
                {
                    sensitivity: "accent"
                }
            )
        );
        setOptions(opt);
    }, [user]);

    // const toggleRestaurantStatus = async (identifier) => {
    //     const { success, response } = await toggleStatus(identifier);
    //     if (success && response) {
    //         const newData = [...options];
    //         const idx = newData.findIndex(
    //             (x) => x.identifier.identifier === identifier
    //         );
    //         newData[idx] = {
    //             ...newData[idx],
    //             identifier: {
    //                 ...newData[idx].identifier,
    //                 status:
    //                     newData[idx].identifier.status === "active"
    //                         ? "inactive"
    //                         : "active"
    //             }
    //         };
    //         setOptions(newData);
    //     }
    // };

    return (
        <>
            <Root
                className={classes.container}
                style={options.length <= 1 ? { display: "none" } : {}}
                onClick={() => setOpen(true)}
            >
                <Icon name="store" />
                <span>
                    <p className={classes.restaurantName}>{display_name}</p>
                </span>
                <Icon name="arrowForward" />
            </Root>
            <Modal
                style={{
                    display: "flex",
                    aliginItems: "center",
                    justifyContent: "center"
                }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Root className={classes.modal}>
                    <Icon
                        name="clear"
                        className={classes.closeButton}
                        onClick={() => setOpen(false)}
                    />
                    <h3 className={classes.title}>
                        Select a Restaurant to View
                    </h3>
                    <div style={{ margin: "0 20px 16px", height: 36 }}>
                        <SearchBar
                            clearSearchText={() => setSearch("")}
                            searchText={search}
                            placeholder="Search"
                            setSearchText={setSearch}
                        />
                    </div>
                    <ul className={classes.list}>
                        {options
                            .filter((x) =>
                                x.identifier.display_name.match(
                                    new RegExp(search, "i")
                                )
                            )
                            .map((x) => (
                                <li
                                    key={x.identifier._id}
                                    className={`${classes.row} ${
                                        x.identifier.status !== "active"
                                            ? classes.inactive
                                            : ""
                                    }`}
                                >
                                    <span style={{ flexGrow: 1 }}>
                                        {x.identifier.display_name}
                                    </span>
                                    {/* {user.role === "super_admin" && (
                                        <CustomSwitch
                                            checked={
                                                x.identifier.status === "active"
                                            }
                                            onChange={() =>
                                                toggleRestaurantStatus(
                                                    x.identifier.identifier
                                                )
                                            }
                                        />
                                    )} */}
                                    {_id === x.identifier._id ? (
                                        <span
                                            style={{
                                                width: 64,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "right"
                                            }}
                                        >
                                            <Icon name="checkButton" />
                                        </span>
                                    ) : (
                                        <span
                                            className={classes.changeButton}
                                            onClick={() =>
                                                handleChangeRestaurant(x)
                                            }
                                        >
                                            View
                                            <Icon name="arrowRight2" />
                                        </span>
                                    )}
                                </li>
                            ))}
                    </ul>
                </Root>
            </Modal>
        </>
    );
};

export default RestaurantSelector;
