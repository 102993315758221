import { useContext, useState } from "react";
import AuthGlobal from "../../context/store/global";
import Button from "./Button";
import SearchBar from "./SearchBar";
// import PublishButton from "../PublishButton";
import {
    Root,
    classes,
    InfoMessage
} from "../../assets/styles/menus/MenuHeader";
import Icon from "./Icon";
import { useAlert } from "../../hooks/useAlert";
import { runPublishCompiler } from "../../utils/Menus/menu-helper";
import ConfirmAction from "../modals/ConfirmAction";
import { refreshRestaurant } from "../../utils/Restaurants/restaurant-helper";
import Alert from "./Alert";
import { useSearchParams } from "react-router-dom";

const Header = ({
    title,
    onClickCreate,
    showSearchBar = true,
    createButtonText,
    enableForcePublish = false,
    ...searchProps
}) => {
    const {
        dispatch,
        globalState: {
            current_restaurant: { logo_image, identifier, outdated },
            current_restaurant
        }
    } = useContext(AuthGlobal);
    const [hidePublishBanner, setHidePublishBanner] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [alert, handleAlert] = useAlert();
    const [disableBtn, setDisableBtn] = useState(false);

    const publish = async () => {
        setDisableBtn(true);
        const { success, response } = await runPublishCompiler(identifier);
        if (success && response) {
            await refreshRestaurant(dispatch, identifier, current_restaurant);
            handleAlert({
                severity: "success",
                title: "Success",
                message: response.message
            });
        } else {
            handleAlert({
                severity: "error",
                title: "Error",
                message: response.message
            });
        }
        setDisableBtn(false);
        setShowModal(false);
    };

    const searchParams = useSearchParams()[0];
    const infoMessage = () => {
        let tabname = "";
        if (window.location.pathname === "/settings") {
            if (searchParams.get("tab") === "6") {
                tabname = "Presenter Settings";
            } else if (searchParams.get("tab") === "7") {
                tabname = "Web Template";
            }
            return tabname.length > 0 ? (
                <InfoMessage key={0} className={classes.infoMessage}>
                    <Icon name="info" />
                    <p>
                        Be sure to save the changes on your {tabname} before
                        publishing your restaurant, otherwise you'll lose any
                        unsaved changes
                    </p>
                </InfoMessage>
            ) : (
                ""
            );
        }
        return "";
    };

    return (
        <>
            <Alert {...alert} />
            <Root className={classes.header}>
                <div
                    className={classes.banner}
                    style={
                        !hidePublishBanner && outdated
                            ? { opacity: 1 }
                            : { opacity: 0 }
                    }
                >
                    <span className={classes.publishMessage}>
                        <Icon name="error" />
                        <span>
                            You have made an update to your Active Menus. Would
                            you like to publish these changes to your live menu?
                            <span
                                className={classes.inlinePublish}
                                onClick={() => setShowModal(true)}
                            >
                                Publish
                            </span>
                        </span>
                    </span>
                    <span
                        className={classes.dismissBanner}
                        onClick={() => setHidePublishBanner(true)}
                    >
                        <Icon name="clear" />
                    </span>
                </div>
                {logo_image && (
                    <img src={logo_image} className="logo-img" alt="logo" />
                )}
                {!logo_image && (
                    <span className={classes.restaurantName}>{title}</span>
                )}
                <div>
                    {showSearchBar && (
                        <span className="input-container">
                            <SearchBar {...searchProps} />
                        </span>
                    )}
                    {typeof onClickCreate === "function" && (
                        <span>
                            <Button
                                onClick={onClickCreate}
                                className={
                                    createButtonText === "Menu"
                                        ? "new_menu_button"
                                        : createButtonText === "New Item"
                                        ? "new_item_button"
                                        : ""
                                }
                                type="secondary"
                                innerText={createButtonText}
                                icon="plus"
                                color="orange"
                            />
                        </span>
                    )}
                    {/* <span>
                        <PublishButton
                            enableForcePublish={enableForcePublish}
                        />
                    </span> */}
                </div>
            </Root>
            <ConfirmAction
                open={showModal}
                actionConfirm={publish}
                actionCancel={() => setShowModal(false)}
                icon="publish"
                dialogText={`Do you want to publish the changes in your menu?`}
                note={infoMessage()}
                disableBtn={disableBtn}
            />
        </>
    );
};

export default Header;
