import { useState, useContext } from "react";
import AuthGlobal from "../../../context/store/global";
import {
    Root,
    classes,
    roleDropdown
} from "../../../assets/styles/settings/UserList";
import "../../../assets/scss/User.scss";
import "antd/lib/tabs/style/index.css";
import "antd/lib/notification/style/index.css";
import "antd/lib/dropdown/style/index.css";
import {
    // CircularProgress,
    FormControlLabel,
    Menu,
    MenuItem,
    Switch,
    Table,
    TableRow
} from "@mui/material";
import CustomTooltip from "../../../components/UI/CustomTooltip";
import Icon from "../../../components/UI/Icon";
import ConfirmAction from "../../../components/modals/ConfirmAction";
import { client } from "../../../utils/apiClient";
import Cookie from "js-cookie";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";

const UsersTab = ({ users, reload }) => {
    const token = Cookie.get("sm_c_t");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedUser, setSelectedUser] = useState({});
    const [selectedUserRole, setSelectedUserRole] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userDelete, setUserDelete] = useState({});
    const theme = useTheme();

    const {
        globalState: {
            current_restaurant: { identifier, _id },
            user: authenticatedUser
        }
    } = useContext(AuthGlobal);

    const authUser_role =
        authenticatedUser.user_permissions.find(
            (x) => x.identifier.identifier === identifier
        )?.user_role || "user";

    const handleClick = (event, row) => {
        setSelectedUser(row);
        setSelectedUserRole(
            row.user_permissions.find((x) => x.identifier === _id)?.user_role ||
                "user"
        );
        if (canAlterUser(row) && row.role !== "account_owner") {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRoleChange = async (new_role) => {
        const lastOwner_update = Cookie.get("sm_olu");
        setAnchorEl(null);
        if (new_role === selectedUserRole) {
            return;
        }
        if (new_role === "account_owner" && lastOwner_update) {
            reload({
                severity: "error",
                title: "Error",
                message:
                    "You have to wait 1 minute before update the account owner again"
            });
            return;
        }

        if (new_role === "account_owner") {
            //owner last update
            const sessionExpireTime = new Date(
                new Date().getTime() + 1 * 60 * 1000
            );
            Cookie.set("sm_olu", true, { expires: sessionExpireTime });
        }

        const { success, response } = await client(
            "user/admin-update-user",
            {
                ..._.pick(selectedUser, [
                    "email",
                    "first_name",
                    "last_name",
                    "_id"
                ]),
                user_role: new_role,
                status: selectedUser.user_permissions.find(
                    (x) => x.identifier === _id
                )?.status,
                identifier
            },
            "PUT",
            {
                Authorization: `${token}`
            }
        );
        if (success && response) {
            reload({
                severity: "success",
                title: "Success",
                message: "User successfully updated"
            });
        } else {
            reload({
                severity: "error",
                title: "Error",
                message: response.message || response.error
            });
        }
    };

    const canAlterUser = (targetUser) => {
        if (
            authenticatedUser.user_permissions.find(
                (x) => x.identifier._id === _id
            )?.user_role === "account_owner"
        ) {
            return true;
        }
        return targetUser.role !== "account_owner";
    };

    const getLabel = (value) => {
        if (value === "admin") {
            return "Administrator";
        }
        if (value === "user") {
            return "User";
        }
        if (value === "customer_support") {
            return "Customer Support";
        }
        return "Account Owner";
    };

    const confirmDeleteUser = async () => {
        const { success, response } = await client(
            `user/admin-delete-user/${identifier}/${userDelete._id}`,
            null,
            "DELETE",
            {
                Authorization: `${token}`
            }
        );
        if (success && response) {
            setShowDeleteModal(false);
            reload({
                severity: "success",
                title: "Success",
                message: "User deleted successfully"
            });
        }
    };

    const toggleUserStatus = async (userId) => {
        const { success, response } = await client(
            `user/toggle-status/${identifier}/${userId}`,
            null,
            "PUT",
            {
                Authorization: `${token}`
            }
        );
        if (success && response) {
            reload({
                severity: "success",
                title: "Success",
                message: "User updated successfully"
            });
        }
    };

    const newTempPassword = async (email) => {
        const data = {
            email: email
        };

        const { success, response } = await client(
            "user/admin-set-password",
            data,
            "POST",
            {
                Authorization: `${token}`
            }
        );

        if (success && response) {
            reload({
                severity: "success",
                title: "Success",
                message: response.message
            });
        } else {
            reload({
                severity: "error",
                title: "Error",
                message: response.error
            });
        }
    };

    return (
        <Root>
            {users.length > 0 ? (
                <Table
                    style={{
                        marginTop: 24,
                        marginBottom: 32,
                        minHeight: "calc(100vh - 335px)",
                        tableLayout: "fixed",
                        overflowY: "auto"
                    }}
                >
                    <tbody>
                        {users.map((row, index) =>
                            row.role === "customer_support" &&
                            authenticatedUser.role === "super_admin" ? (
                                <TableRow
                                    key={index}
                                    className={`${classes.tableRow} ${
                                        index % 2 === 0
                                            ? classes.color1
                                            : classes.color2
                                    }`}
                                >
                                    <td style={{ width: 275 }}>
                                        <span>
                                            {`${row.first_name} ${row.last_name}`}
                                        </span>
                                    </td>
                                    <td
                                        className={classes.textLight}
                                        style={{
                                            fontWeight: 400,
                                            width: 150
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: 140,
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                cursor: "pointer"
                                            }}
                                            onClick={(e) => handleClick(e, row)}
                                        >
                                            {getLabel(row.role)}
                                            {canAlterUser(row) &&
                                                row.role !==
                                                    "account_owner" && (
                                                    <span
                                                        style={{
                                                            height: "100%",
                                                            width: "15px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "right"
                                                        }}
                                                    >
                                                        <Icon name="arrowDown" />
                                                    </span>
                                                )}
                                        </span>
                                    </td>
                                    <td
                                        className={classes.textLight}
                                        style={{
                                            fontWeight: 300,
                                            minWidth: "40px",
                                            width: "calc(100% - 680px)",
                                            cursor: "default",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <CustomTooltip title={row.email}>
                                            <span>{row.email}</span>
                                        </CustomTooltip>
                                    </td>
                                    <td className={classes.actions}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        row.status === "active"
                                                    }
                                                    classes={{
                                                        switchBase:
                                                            classes.switchDisabled
                                                    }}
                                                    className={
                                                        row.status === "active"
                                                            ? classes.switch
                                                            : ""
                                                    }
                                                    disabled={
                                                        !canAlterUser(row) ||
                                                        row.role ===
                                                            "account_owner" ||
                                                        (authenticatedUser._id ===
                                                            row._id &&
                                                            authUser_role ===
                                                                row.role)
                                                    }
                                                />
                                            }
                                            style={
                                                row.role === "account_owner"
                                                    ? {
                                                          opacity: 0
                                                      }
                                                    : {}
                                            }
                                            label={
                                                row.status === "active"
                                                    ? "Active"
                                                    : "Inactive"
                                            }
                                            onChange={() => {
                                                toggleUserStatus(row._id);
                                            }}
                                            className={classes.switchLabel}
                                        />

                                        <CustomTooltip title="Resend invitation">
                                            <span
                                                style={
                                                    canAlterUser(row)
                                                        ? {}
                                                        : {
                                                              pointerEvents:
                                                                  "none",
                                                              opacity: "40%"
                                                          }
                                                }
                                                onClick={() => {
                                                    newTempPassword(row.email);
                                                }}
                                            >
                                                <Icon name="refresh" />
                                            </span>
                                        </CustomTooltip>
                                        <CustomTooltip title={"Delete user"}>
                                            <span
                                                style={
                                                    canAlterUser(row) &&
                                                    row.role !==
                                                        "account_owner" &&
                                                    !(
                                                        authenticatedUser._id ===
                                                            row._id &&
                                                        authUser_role ===
                                                            row.role
                                                    )
                                                        ? {}
                                                        : {
                                                              pointerEvents:
                                                                  "none",
                                                              opacity: "40%"
                                                          }
                                                }
                                                onClick={() => {
                                                    if (
                                                        !(
                                                            authenticatedUser._id ===
                                                                row._id &&
                                                            authUser_role ===
                                                                row.role
                                                        )
                                                    ) {
                                                        setUserDelete(row);
                                                        setShowDeleteModal(
                                                            true
                                                        );
                                                    }
                                                }}
                                            >
                                                <Icon name="trash" />
                                            </span>
                                        </CustomTooltip>
                                    </td>
                                </TableRow>
                            ) : row.role !== "customer_support" ? (
                                <TableRow
                                    key={index}
                                    className={`${classes.tableRow} ${
                                        index % 2 === 0
                                            ? classes.color1
                                            : classes.color2
                                    }`}
                                >
                                    <td style={{ width: 275 }}>
                                        <span>
                                            {`${row.first_name} ${row.last_name}`}
                                        </span>
                                    </td>
                                    <td
                                        className={classes.textLight}
                                        style={{
                                            fontWeight: 400,
                                            width: 150
                                        }}
                                    >
                                        <span
                                            style={{
                                                width: 140,
                                                height: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                cursor: "pointer"
                                            }}
                                            onClick={(e) => handleClick(e, row)}
                                        >
                                            {getLabel(row.role)}
                                            {canAlterUser(row) &&
                                                row.role !==
                                                    "account_owner" && (
                                                    <span
                                                        style={{
                                                            height: "100%",
                                                            width: "15px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "right"
                                                        }}
                                                    >
                                                        <Icon name="arrowDown" />
                                                    </span>
                                                )}
                                        </span>
                                    </td>
                                    <td
                                        className={classes.textLight}
                                        style={{
                                            fontWeight: 300,
                                            minWidth: "40px",
                                            width: "calc(100% - 680px)",
                                            cursor: "default",
                                            overflow: "hidden"
                                        }}
                                    >
                                        <CustomTooltip title={row.email}>
                                            <span>{row.email}</span>
                                        </CustomTooltip>
                                    </td>
                                    <td className={classes.actions}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        row.status === "active"
                                                    }
                                                    classes={{
                                                        switchBase:
                                                            classes.switchDisabled
                                                    }}
                                                    className={
                                                        row.status === "active"
                                                            ? classes.switch
                                                            : ""
                                                    }
                                                    disabled={
                                                        !canAlterUser(row) ||
                                                        row.role ===
                                                            "account_owner" ||
                                                        (authenticatedUser._id ===
                                                            row._id &&
                                                            authUser_role ===
                                                                row.role)
                                                    }
                                                />
                                            }
                                            style={
                                                row.role === "account_owner"
                                                    ? {
                                                          opacity: 0
                                                      }
                                                    : {}
                                            }
                                            label={
                                                row.status === "active"
                                                    ? "Active"
                                                    : "Inactive"
                                            }
                                            onChange={() => {
                                                toggleUserStatus(row._id);
                                            }}
                                            className={classes.switchLabel}
                                        />

                                        <CustomTooltip title="Resend invitation">
                                            <span
                                                style={
                                                    canAlterUser(row)
                                                        ? {}
                                                        : {
                                                              pointerEvents:
                                                                  "none",
                                                              opacity: "40%"
                                                          }
                                                }
                                                onClick={() => {
                                                    newTempPassword(row.email);
                                                }}
                                            >
                                                <Icon name="refresh" />
                                            </span>
                                        </CustomTooltip>
                                        <CustomTooltip title={"Delete user"}>
                                            <span
                                                style={
                                                    canAlterUser(row) &&
                                                    row.role !==
                                                        "account_owner" &&
                                                    !(
                                                        authenticatedUser._id ===
                                                            row._id &&
                                                        authUser_role ===
                                                            row.role
                                                    )
                                                        ? {}
                                                        : {
                                                              pointerEvents:
                                                                  "none",
                                                              opacity: "40%"
                                                          }
                                                }
                                                onClick={() => {
                                                    if (
                                                        !(
                                                            authenticatedUser._id ===
                                                                row._id &&
                                                            authUser_role ===
                                                                row.role
                                                        )
                                                    ) {
                                                        setUserDelete(row);
                                                        setShowDeleteModal(
                                                            true
                                                        );
                                                    }
                                                }}
                                            >
                                                <Icon name="trash" />
                                            </span>
                                        </CustomTooltip>
                                    </td>
                                </TableRow>
                            ) : null
                        )}
                    </tbody>
                </Table>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "250px 0"
                    }}
                >
                    <p>No user Found!</p>
                    {/* <CircularProgress
                        style={{ color: "#CC5803", width: 60, height: 60 }}
                    /> */}
                </div>
            )}
            <Menu
                id={`role_menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ "aria-labelledby": "role_dropdown" }}
                PaperProps={{
                    sx: roleDropdown(theme),
                    className: classes.roleDropdown
                }}
                style={{ transform: "translateX(-10px)" }}
            >
                {authenticatedUser.role === "super_admin" && (
                    <MenuItem
                        selected={selectedUserRole === "account_owner"}
                        value="Account Owner"
                        onClick={() => handleRoleChange("account_owner")}
                    >
                        Account Owner
                    </MenuItem>
                )}
                <MenuItem
                    selected={selectedUserRole === "admin"}
                    value="Administrator"
                    onClick={() => handleRoleChange("admin")}
                >
                    Administrator
                </MenuItem>
                <MenuItem
                    selected={selectedUserRole === "user"}
                    value="User"
                    onClick={() => handleRoleChange("user")}
                >
                    User
                </MenuItem>
                {authenticatedUser.role === "super_admin" && (
                    <MenuItem
                        selected={selectedUserRole === "customer_support"}
                        value="Customer Support"
                        onClick={() => handleRoleChange("customer_support")}
                    >
                        Customer Support
                    </MenuItem>
                )}
            </Menu>

            <ConfirmAction
                open={showDeleteModal}
                dialogText="Are you sure want to permanently delete this user?"
                icon="trash"
                actionCancel={() => {
                    setShowDeleteModal(false);
                }}
                actionConfirm={confirmDeleteUser}
            />
        </Root>
    );
};

export default UsersTab;
